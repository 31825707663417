// Next
import Link from 'next/link'
import { useState } from 'react'

// Bootstrap
import {
	Col,
	Row,
	Image,
	Container,
	Modal,
	Button,
	NavLink
} from 'react-bootstrap'

// Style
import styles from '../../styles/Index.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDirections } from '@fortawesome/free-solid-svg-icons'
import FindjobsButton from '../common/button/FindjobsButton'

const Contact = () => {
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)

	return (
		<>
			<div className='pb-5' id="contact_us">
				<Container>
					{/* <div className='text-center'>
						<h3 className={styles.subtitle}>{t('company_landing_page.questions_et_us_know')}</h3>
						<p className={styles.description}>{t('company_landing_page.our_dedicated_team_is_ready_to_help_you_with_any_enquiries')}</p>
					</div> */}
					<Row>
						<Col xs={12} sm={6} className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/landing_page/contact.png' alt='' width='65%' className='mb-5 mr-2' />
						</Col>
						<Col className='d-flex flex-column justify-content-center'>
							<Row className='mb-4'>
								<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
									<Image src='/img/landing_page/icons/phone.png' width='30px' alt='' />
								</Col>
								<Col className='d-flex justify-content-start align-items-start'>
									<p className={styles.text}>+65 6916 4240</p>
								</Col>
							</Row>
							<Row className='mb-4'>
								<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
									<Image src='/img/landing_page/icons/Envelope.png' width='30px' alt='' />
								</Col>
								<Col className='d-flex justify-content-start align-items-start'>
									<p className={styles.text}>sales@findjobs.com.sg</p>
								</Col>
							</Row>
							<Row className='mb-4'>
								<Col xs={2} lg={1} className='align-items-center'>
									<Image src='/img/landing_page/icons/location.png' width='30px' className='pt-1' alt='' />
								</Col>
								<Col className='d-flex flex-column justify-content-start align-items-start'>
									<p className={styles.text}>75 Ayer Rajah Crescent,<br />#01-04 Singapore 139953</p>
									<Button onClick={() => setShowModal(true)} variant='link' className='text-decoration-none'>{t('footer.view_map')}</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal size='lg' show={showModal} onHide={() => { setShowModal(false) }} dialogClassName={styles.modalBorder}>
				<Modal.Header closeButton style={{ border: 'none', paddingBottom: '0' }} />
				<Modal.Title><h4 className='text-center font-weight-700'>Our Location</h4></Modal.Title>
				<Modal.Body>
					<div className={styles.viewMap}>
						<iframe src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7977.591048716865!2d103.786321!3d1.297373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5bc0ec89fb94563a!2sFindjobs%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1631175371352!5m2!1sen!2ssg' width='100%' height='450' style={{ border: '0' }} allowFullScreen={false} loading='lazy' />
					</div>
					<div className='text-center mt-4 mb-3'>
						<Link href='https://goo.gl/maps/xW8mfeimTZrgxR5f7' passHref>
							<NavLink>
								<FindjobsButton text={
									<>
										<FontAwesomeIcon icon={faDirections} />
										{t('company_landing_page.direction')}
									</>
								} type={undefined} />
							</NavLink>
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Contact