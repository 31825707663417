// Mext
import Link from 'next/link'

// Bootstrap
import {
	Col,
	Row,
	Container,
	NavLink,
	Image
} from 'react-bootstrap'

// Style
import styles from '../../styles/Index.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCircleCheck
} from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'

// Components
import FindjobsButton from '../common/button/FindjobsButton'


const Partnership = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className='pt-5'>
				<Container>
					<div className='text-center mb-5'>
						<h3 className={styles.subtitle}>{t('company_landing_page.partner_with_us')}</h3>
						{/* <p className={styles.description}>{t('company_landing_page.partner_with_us_description')}</p> */}
					</div>
					<Row style={{ marginTop: '50px', marginBottom: '50px' }}>
						{/* <Col xs={12} md={6}>
							<h1 className={styles.title}>
								{t('company_landing_page.build_your_team_with_our_help')}
							</h1>
						</Col> */}
						<Col className='d-flex flex-column justify-content-center align-items-center'>
							<div className='mb-5'>
								<Row className='mb-3'>
									<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
										{/* <FontAwesomeIcon width='30px' icon={faCircleCheck} className={styles.iconCheck} /> */}
										<Image src='/img/partner/kiosk.png' width='20' alt='find jobs near your home' />
									</Col>
									<Col className='d-flex justify-content-start align-items-start'>
										<p className={styles.text}>{t('company_landing_page.islandwide_kiosks')}</p>
									</Col>
								</Row>
								<Row className='mb-3'>
									<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
										{/* <FontAwesomeIcon width='30px' icon={faCircleCheck} className={styles.iconCheck} /> */}
										<Image src='/img/partner/jobp.png' width='20' alt='find jobs near your home' />
									</Col>
									<Col className='d-flex justify-content-start align-items-start'>
										<p className={styles.text}>{t('company_landing_page.job_placement')}</p>
									</Col>
								</Row>
								<Row className='mb-3'>
									<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
										{/* <FontAwesomeIcon width='30px' icon={faCircleCheck} className={styles.iconCheck} /> */}
										<Image src='/img/partner/social.png' width='20' alt='find jobs near your home' />
									</Col>
									<Col className='d-flex justify-content-start align-items-start'>
										<p className={styles.text}>{t('company_landing_page.social_media_marketing_campaigns')}</p>
									</Col>
								</Row>
								<Row className='mb-3'>
									<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
										{/* <FontAwesomeIcon width='30px' icon={faCircleCheck} className={styles.iconCheck} /> */}
										<Image src='/img/partner/walkin.png' width='20' alt='find jobs near your home' />
									</Col>
									<Col className='d-flex justify-content-start align-items-start'>
										<p className={styles.text}>{t('company_landing_page.walk_in_interviews')}</p>
									</Col>
								</Row>
								<Row className='mb-3'>
									<Col xs={2} lg={1} className='d-flex justify-content-center align-items-center'>
										{/* <FontAwesomeIcon width='30px' icon={faCircleCheck} className={styles.iconCheck} /> */}
										<Image src='/img/partner/vcf.png' width='20' alt='find jobs near your home' />
									</Col>
									<Col className='d-flex justify-content-start align-items-start'>
										<p className={styles.text}>{t('company_landing_page.virtual_career_fair')}</p>
									</Col>
								</Row>
							</div>
							<div className='d-flex flex-column flex-sm-row justify-content-start align-items-center'>
								<div className='mx-1 my-1'>
									<Link href='#contact_us' passHref>
										<NavLink>
											<FindjobsButton text={t('company_landing_page.contact_us')} type='' />
										</NavLink>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default Partnership