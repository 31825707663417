// Bootstrap
import {
	Col,
	Row,
	Image,
	Container
} from 'react-bootstrap'

// Style
import styles from '../../styles/Index.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import { useTranslation } from 'react-i18next'

const About = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className={`${styles.about} py-5`}>
				<Container>
					{/* <div className='text-center my-5'>
						<h3 className={styles.subtitle}>{t('company_landing_page.about_title')}</h3>
						<p className={styles.description}>{t('company_landing_page.about_description')}</p>
					</div> */}
					<Row className='d-flex flex-column flex-md-row'>
						<Col className='d-flex justify-content-center align-items-center' xs={12} md={5}>
							<Image src='/img/landing_page/findjobs-logo-license.png' alt='Findjobs License' width='300px' className={`${styles.image} mb-5`} />
						</Col>
						<Col>
							<h3 className={styles.subtitle}>{t('company_landing_page.about_findjobs')}</h3>
							{/* <p className={styles.text}>{t('company_landing_page.about_findjobs_description')}</p> */}
							<p className={styles.text}>Founded in 2016, Findjobs is an award winning technology start-up in Singapore with their &quot;easy to use&quot; multi-language mobile app (Findjobs app) which connects job opportunities to non-executive jobseekers seamlessly. Additionally, Findjobs has also developed and deployed Smart Job Kiosks across various locations in Singapore. Leveraging on both the Smart Job Kiosks and the mobile app, Findjobs has managed to bridge the gap between online job openings and offline jobseekers, making job search inclusive and a breeze for everyone.
							</p>
						</Col>
					</Row>
					<div className='text-center my-5'>
						<h3 className={styles.subtitle}>{t('company_landing_page.media_and_awards')}</h3>
						{/* <p className={styles.description}>{t('company_landing_page.view_our_news_for_your_updated_information_in_job_portal_field')}</p> */}
						<div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gx-2">
							<Image src='/img/landing_page/STANLEY_AWARD_1.jpeg' alt='awards' height="300px" className={`mx-2 mt-4 ${styles.awardImage}`} />
							<Image src='/img/landing_page/STANLEY_AWARD_2.jpeg' alt='awards' height="300px" className={`mx-2 mt-4 ${styles.awardImage}`} />
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default About