import { useRouter } from 'next/router'
import {
	Image
} from 'react-bootstrap'
import Slider from 'react-slick'

const FindjobsHiringCompanyCarousellList = () => {
	const router = useRouter()
	const landing = ['/', '/employer']

	const HiringCompanies = [
		{
			image: '/img/partners/raise_logo-300x300.png',
			alt: 'Findjobs Raise'
		},
		{
			image: '/img/partners/msf_loog-300x300.png',
			alt: 'Findjobs MSF'
		},
		{
			image: '/img/partners/e2i_logo-300x300.png',
			alt: 'Findjobs e2i'
		},
		{
			image: '/img/partners/aic_logo-300x300.png',
			alt: 'Findjobs AIC'
		},
		{
			image: '/img/partners/5Sense.png',
			alt: 'Findjobs Sense'
		},
		{
			image: '/img/partners/6skillsfuture.png',
			alt: 'Findjobs Skillsfuture'
		},
		{
			image: '/img/partners/13sinda.png',
			alt: 'Findjobs Sinda'
		},
		{
			image: '/img/partners/7Toa-payoh-east-novena.png',
			alt: 'Findjobs Findjobs Toa Payoh East Novena'
		},
		{
			image: '/img/partners/8People-association.png',
			alt: 'Findjobs People Association'
		},
		{
			image: '/img/partners/necdc_logo-300x300.png',
			alt: 'Findjobs NECDC'
		},
		{
			image: '/img/partners/11south-east-cdc.png',
			alt: 'Findjobs South East CDC'
		},
		{
			image: '/img/partners/10south-west-cdc.png',
			alt: 'Findjobs South West CDC'
		},
		{
			image: '/img/partners/12west-coast-grc.png',
			alt: 'Findjobs West Coast GRC'
		},
		{
			image: '/img/partners/14SMRT.png',
			alt: 'Findjobs Skills'
		},
		{
			image: '/img/partners/15Maybank.png',
			alt: 'Findjobs Maybank'
		},
		{
			image: '/img/partners/pertapis.png',
			alt: 'Findjobs Pertapis'
		},
		{
			image: '/img/partners/smrt-trans.png',
			alt: 'Findjobs SMRT Trans'
		},
		{
			image: '/img/partners/public-service-division.png',
			alt: 'Findjobs Public Service Division'
		}
	]

	let settings = {}
	if (landing.includes(router.pathname)) {
		settings = {
			dots: false,
			autoplay: true,
			infinite: true,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 4,
			initialSlide: 0,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 764,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 300,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				}
			]
		}
	} else {
		settings = {
			dots: false,
			autoplay: true,
			infinite: true,
			speed: 500,
			slidesToShow: 10,
			slidesToScroll: 4,
			initialSlide: 0,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 4,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				}
			]
		}
	}

	return (
		<>
			<Slider {...settings}>
				{
					HiringCompanies.map((data, i) => (
						<div key={i}>
							<Image height='100px' src={data.image} alt={data.alt} />
						</div>
					))
				}
			</Slider>
		</>
	)
}

export default FindjobsHiringCompanyCarousellList