import React, { useCallback, useState } from 'react'

// Bootstrap
import { Container, Col, Form, Row } from 'react-bootstrap'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'

// Style
import styles from '../../styles/Index.module.scss'

const mapContainerStyle = {
	height: '840px',
	width: '100%'
}

const Kiosk = () => {
	const [centerLat, setCenterLat] = useState(1.3521)
	const [centerLng, setCenterLng] = useState(() => {
		if (typeof window !== 'undefined' && window.innerWidth <= 576) return 103.8198
		else return 103.75
	})
	const [zoom, setZoom] = useState(() => {
		if (typeof window !== 'undefined' && window.innerWidth <= 576) return 11
		else return 12
	})
	const [name, setName] = useState('All Kiosk Location')
	const [address, setAddress] = useState('Islandwide')
	const [partner, setPartner] = useState('Findjobs')
	const [screenSize] = useState(() => {
		if (typeof window !== 'undefined' && window.innerWidth <= 576) return 'xs'
		else return 'lg'
	}) // xs || lg

	const center = {
		lat: centerLat,
		lng: centerLng
	}

	const kisokList = [
		{
			name: 'e2i West',
			address: 'Devan Nair Institute for Employment and Employability 80 Jurong East St 21, Level 2 Singapore 609607',
			partner: 'Employment & Employability Institute e2i Career Centres',
			location: {
				lat: 1.336895343612757,
				lng: 103.74452596930814
			}
		},
		{
			name: 'e2i toa payoh',
			address: '490 Lorong 6 Toa Payoh, #07-11 (Lift Lobby 1) Singapore 310490',
			partner: 'Employment & Employability Institute e2i Career Centres',
			location: {
				lat: 1.3336942173272959,
				lng: 103.84850331379664
			}
		},
		{
			name: 'SSO Ang Mo Kio',
			address: '6A Ang Mo Kio St 53 Ang Mo Kio 3G Centre Singapore 569208',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.3786834698634187,
				lng: 103.84919029822309
			}
		},
		{
			name: 'SSO Boon Lay',
			address: 'Blk 189 Boon Lay Drive #01-254 Singapore 640189',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.3447571460628123,
				lng: 103.7114694114548
			}
		},
		{
			name: 'SSO Geylang Serai',
			address: '10 Eunos Road 8 #12-02 Singapore Post Centre Singapore 408600 (from North Lobby)',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.3191548667583195,
				lng: 103.89456669956824
			}
		},
		{
			name: 'SSO Choa Chu Kang',
			address: '8A Teck Whye Lane Singapore 681008',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.3811506616195912,
				lng: 103.75404961079029
			}
		},
		{
			name: 'SSO Seng Kang',
			address: 'Blk 261C Sengkang East Way #01-506 Singapore 543261',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.393564337913616,
				lng: 103.89490862680053
			}
		},
		{
			name: 'SSO Taman Jurong',
			address: '301A Corporation Drive Singapore 619773',
			partner: 'Social Service Office (SSO)',
			location: {
				lat: 1.335170147119207,
				lng: 103.72222227098311
			}
		},
		{
			name: 'CC Pioneer',
			address: '60 Jurong West Central 3, #01-01, Jurong West Community Building, Singapore 648346',
			partner: 'Community Centres (CC)',
			location: {
				lat: 1.3405737206242605,
				lng: 103.70439724029134
			}
		},
		{
			name: 'CC Marymount',
			address: '191 Sin Ming Ave, Singapore 575738',
			partner: 'Community Centres (CC)',
			location: {
				lat: 1.3631132472232794,
				lng: 103.84123778261826
			}
		},
		{
			name: 'CC Hougang',
			address: '35 Hougang Ave 3, Singapore 538840',
			partner: 'Community Centres (CC)',
			location: {
				lat: 1.3649014696116537,
				lng: 103.89234489796422
			}
		},
		{
			name: 'CC Cheng San',
			address: '1 North Point Dr, Singapore 768019',
			partner: 'Community Centres (CC)',
			location: {
				lat: 1.4284438875287218,
				lng: 103.83594722680051
			}
		},
		{
			name: 'CC Nee Soon',
			address: '6 Ang Mo Kio Street 53, Singapore 569205',
			partner: 'Community Centres (CC)',
			location: {
				lat: 1.3722736945091611,
				lng: 103.84966052865603
			}
		},
		{
			name: 'MPS Toa Payoh East',
			address: 'Blk 261A, Toa Payoh East, #01-02, Singapore 311261',
			partner: 'Meet The People Session (MPS)',
			location: {
				lat: 1.3339937953899532,
				lng: 103.85574561331015
			}
		},
		{
			name: 'MPS Bukit Panjang',
			address: 'Blk 532 Jelapang Road #01-33 Singapore 670532',
			partner: 'Meet The People Session (MPS)',
			location: {
				lat: 1.3852521415178716,
				lng: 103.7645886691277
			}
		},
		{
			name: 'MPS Yio Chu Kang',
			address: 'Yio Chu Kang branch, Blk 644 #01-850 Singapore 560644',
			partner: 'Meet The People Session (MPS)',
			location: {
				lat: 1.3630809956250696,
				lng: 103.8736585691277
			}
		},
		{
			name: 'Heartbeat Bedok',
			address: '11 Bedok North Street 1, Singapore 469662',
			partner: 'Other Locations',
			location: {
				lat: 1.3272541739443453,
				lng: 103.9321600268008
			}
		},
		{
			name: 'Job Hub North East CDC',
			address: 'Blk 526 Bedok North Street 3 (Job Hub @ Kaki Bukit), Singapore 460526',
			partner: 'Other Locations',
			location: {
				lat: 1.3344714212687938,
				lng: 103.92911636912774
			}
		},
		{
			name: 'Our Tampines Hub',
			address: '1 Tampines Walk, Singapore 528523 (Public Service Centre)',
			partner: 'Other Locations',
			location: {
				lat: 1.3529239287535417,
				lng: 103.93963360774403
			}
		},
		{
			name: 'Maybank@Marine Parade',
			address: '87 Marine Parade Central, #01-502, Singapore 440087',
			partner: 'Other Locations',
			location: {
				lat: 1.3028304985004746,
				lng: 103.90562715563722
			}
		},
		{
			name: 'Maybank@Toa Payoh Hub',
			address: 'Block 480 Lor 6 Toa Payoh, #01-11 HDB Hub, Singapore 310480',
			partner: 'Other Locations',
			location: {
				lat: 1.3324271214840442,
				lng: 103.84813989796432
			}
		},
		{
			name: 'SINDA',
			address: '1 Beatty Road Singapore 209943',
			partner: 'Other Locations',
			location: {
				lat: 1.3142527973688638,
				lng: 103.85875319610896
			}
		},
		{
			name: 'Woodland SMRT',
			address: '30 Woodlands Ave 2 Singapore 738343',
			partner: 'Other Locations',
			location: {
				lat: 1.4370791318067548,
				lng: 103.78661308447342
			}
		},
		{
			name: 'Pertapis Halfway House',
			address: '50 Lorong 34 Geylang 398239',
			partner: 'Other Locations',
			location: {
				lat: 1.312781775577889,
				lng: 103.88756705378195
			}
		},
		{
			name: 'Job Hub Kaki Bukit',
			address: 'Blk 526 Bedok North Street 3 Singapore 460526',
			partner: 'Other Locations',
			location: {
				lat: 1.33454650274938,
				lng: 103.9290305384359
			}
		},
		{
			name: 'RC Yio Chu Kang Zone 3',
			address: '121 Ang Mo Kio Ave 3 Singapore 560121',
			partner: 'Other Locations',
			location: {
				lat: 1.3700010947857153,
				lng: 103.84357976912763
			}
		},
		{
			name: 'Ng Teng Fong Hospital (NTF)',
			address: '1 Jurong East Street 21 Ng Teng Fong General Hospital Singapore 609606',
			partner: '"NUHS Hospital',
			location: {
				lat: 1.3343183184735903,
				lng: 103.74541052535312
			}
		},
		{
			name: 'National University Polyclinics (NUP)',
			address: '50 Woodlands Road Singapore 677726',
			partner: '"',
			location: {
				lat: 1.3831645389119844,
				lng: 103.7598718556371
			}
		},
		{
			name: 'SSG Lifelong Learning Institute 1 (inside office)',
			address: '11 Eunos Road 8 Learning Vision @Work Pte Ltd Singapore 408601',
			partner: 'SSG LLI',
			location: {
				lat: 1.320222304685759,
				lng: 103.89240165563723
			}
		},
		{
			name: 'SSG Lifelong Learning Institute 2 (Public Area)',
			address: '11 Eunos Road 8 Learning Vision @Work Pte Ltd Singapore 408601',
			partner: 'SSG LLI',
			location: {
				lat: 1.320222304685759,
				lng: 103.89240165563723
			}
		},
		{
			name: 'Commonwealth Work Work',
			address: '1 Commonwealth Lane, One Commonwealth #06-25/26, Singapore 149544',
			partner: 'WORK WORK PTE LTD',
			location: {
				lat: 1.3046204398793064,
				lng: 103.7967745633103
			}
		},
		{
			name: 'National University Hospital (NUH)',
			address: '5 Lower Kent Ridge Rd, Singapore 119074',
			partner: 'NUHS Hospital',
			location: {
				lat: 1.2948170577527638,
				lng: 103.78322984276741
			}
		},
		{
			name: 'One Punggol',
			address: '1 Punggol Dr., Singapore 828629',
			partner: 'One Punggol',
			location: {
				lat: 1.4078025645275276,
				lng: 103.90466316912763
			}
		}
	]

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY as string
	})

	// const [map, setMap] = useState(null)
	const [foundKiosk, setFoundKiosk] = useState(kisokList)
	const [search, setSearch] = useState('')

	const filter = (e) => {
		const keyword = e.target.value

		if (keyword !== '') {
			// const results = kisokList.filter((kiosk) => {
			// 	// return kiosk.name.toLowerCase().startsWith(keyword.toLowerCase())
			// 	kiosk.name.search(keyword) != -1
			// })
			const results = kisokList.filter((kiosk) => {
				return (kiosk.name.toLowerCase().includes(keyword.toLowerCase()) || kiosk.address.toLowerCase().includes(keyword.toLowerCase()))
			})
			setFoundKiosk(results)
		} else {
			setFoundKiosk(kisokList)
			setCenterLat(centerLat)
			// setCenterLng(103.8198)
			// setZoom(12)
			setCenterLng(screenSize === 'lg' ? 103.75 : 103.8198)
			setZoom(screenSize === 'lg' ? 12 : 11)
			setName('All Kiosk Location')
			setAddress('Singapore')
			setPartner('All')
		}

		setSearch(keyword)
	}


	// const onUnmount = useCallback(function callback(map) {
	// 	setMap(null)
	// }, [])

	return (
		<Container>
			<Row className='py-5'>
				<Col>
					{
						isLoaded ? (
							<>
								<div style={{ position: 'relative', borderRadius: '8px' }}>
									<div className='d-block d-md-none mb-4 px-2'>
										<h1 className={styles.subtitle}>Kiosks Locations</h1>
										<hr />
										<Form.Control
											type='text'
											value={search}
											onChange={filter}
											className='mb-4'
										/>

										<div style={{ height: '450px', overflow: 'auto' }}>
											<div className={`py-3 px-4 my-2 ${styles.kioskList}`} onClick={() => {
												setCenterLat(centerLat)
												// setCenterLng(103.70054374434903)
												// setZoom(12)
												setCenterLng(screenSize === 'lg' ? 103.75 : 103.8198)
												setZoom(screenSize === 'lg' ? 12 : 11)
												setName('All Kiosk Location')
												setAddress('Singapore')
												setPartner('All')
												setSearch('')
												setFoundKiosk(kisokList)
											}}>
												<h6 className={styles.kioskName}><strong>All Kiosk Location</strong></h6>
												<small>Show All Kiosk Location</small>
											</div>
											{
												foundKiosk.map((data, i) => (
													<div key={i} className={`py-3 px-4 my-2 ${styles.kioskList}`} onClick={() => {
														setCenterLat(data.location.lat)
														setCenterLng(data.location.lng)
														setZoom(16)
														setName(data.name as string)
														setAddress(data.address)
														setPartner(data.partner)
													}}>
														<h6 className={styles.kioskName}><strong>{data.name}</strong></h6>
														<small>{data.address}</small>
													</div>
												))
											}
										</div>
									</div>
									<GoogleMap
										mapContainerStyle={mapContainerStyle}
										center={center}
										zoom={zoom}
										// onUnmount={onUnmount}
									>
										{
											foundKiosk.map((data, i) => (
												<MarkerF
													key={i}
													title={data.name}
													position={data.location}
													onClick={() => {
														setCenterLat(data.location.lat)
														setCenterLng(data.location.lng)
														setZoom(16)
														setName(data.name as string)
														setAddress(data.address)
														setPartner(data.partner)
													}}
												/>
											))
										}
									</GoogleMap>
									<div className={`d-none d-md-block ${styles.kioskLocationList}`}>
										<h1 className={styles.subtitle}>Kiosks Location</h1>
										<hr />
										<Form.Control
											type='text'
											value={search}
											onChange={filter}
											className='mb-4'
										/>

										<div style={{ height: '450px', overflow: 'auto' }}>
											<div className={`py-3 px-4 my-2 ${styles.kioskList}`} onClick={() => {
												setCenterLat(centerLat)
												// setCenterLng(103.70054374434903)
												// setZoom(12)
												setCenterLng(screenSize === 'lg' ? 103.75 : 103.8198)
												setZoom(screenSize === 'lg' ? 12 : 11)
												setName('All Kiosk Location')
												setAddress('Singapore')
												setPartner('All')
												setSearch('')
												setFoundKiosk(kisokList)
											}}>
												<h6 className={styles.kioskName}><strong>All Kiosk Location</strong></h6>
												<small>Show All Kiosk Location</small>
											</div>
											{
												foundKiosk.map((data, i) => (
													<div key={i} className={`py-3 px-4 my-2 ${styles.kioskList}`} onClick={() => {
														setCenterLat(data.location.lat)
														setCenterLng(data.location.lng)
														setZoom(16)
														setName(data.name as string)
														setAddress(data.address)
														setPartner(data.partner)
													}}>
														<h6 className={styles.kioskName}><strong>{data.name}</strong></h6>
														<small>{data.address}</small>
													</div>
												))
											}
										</div>
									</div>
									<div className={styles.kioskDesc}>
										<h4 className={styles.kioskName}>{name}</h4>
										<h6><strong>{address}</strong></h6>
										<p>{partner}</p>
									</div>
								</div>
							</>
						) : <></>
					}
				</Col>
			</Row>
		</Container>
	)
}

export default Kiosk