// Bootstrap
import {
	Col,
	Row,
	Container,
	Image
} from 'react-bootstrap'

// Style
import styles from '../../styles/Index.module.scss'
import Slider from 'react-slick'

// Other
import Link from 'next/link'
import FindjobsButton from '../common/button/FindjobsButton'

const Hunting = () => {
	const pressroomList = [
		{
			link: 'https://www.businesstimes.com.sg/life-culture/i-choose-you-making-blue-collar-job-hunting-as-easy-as-pokemon-go?amp',
			text: 'I choose you! Making blue-collar job hunting as easy as Pokemon Go',
			website: 'www.businesstimes.com.sg',
			thumbnail: '\"CATCHING\" a job that is within 10 kilometres of you.\nThat is the technology behind a key feature of Findjobs, an app that is targeted at blue-collar workers.Inspired by Pokemon Go\'s geofencing feature, the app aims to bring the job to workers in the language they\'re comfortable with, and has plans to add dialect versions in the future.'
		},
		{
			link: 'https://www.sgtech.org.sg/SGTECH/Web/SGTech_News_2019/Jul19/Conversations_with_ASA19_Winners.aspx',
			text: 'Conversations with SGTech\'s Asia Smart Apps 2019 Winners - Tueetor, Findjobs & Smartfuture',
			website: 'www.sgtech.org.sg',
			thumbnail: 'As a social enterprise, Findjobs app harness technology to bring online jobs readily available to the non-tech savvy, blue-collar and elderly workers. We removed typical user barriers such as registration, log-in with social media, deposition of resume and creation of passwords and replaced with features such as “enlarge text”, “voice job description”, “broadcast yourself”, Pokemon style of “scan nearby jobs” as well as a simple touch-&-tap non-cv job application. With these features, Findjobs can make the job search effortless and smarter. '
		},
		{
			link: 'https://www.todayonline.com/brand-spotlight/getting-digital-bandwagon',
			text: 'Getting up the digital bandwagon',
			website: 'www.todayonline.com',
			thumbnail: 'The Findjobs app harnesses technology to make jobs advertised online available to more people, including those who are not tech-savvy, and blue-collar and elderly workers. Unlike mainstream job apps, Findjobs removes typical barriers such as online registrations, social media account log-ins, online resume submissions and the creation of passwords.'
		},
		{
			link: 'https://www.sgtech.org.sg/SGTECH/Web/SGTech_News_2019/Jul19/Conversations_with_ASA19_Winners.aspx',
			text: 'Conversations with SGTech\'s Asia Smart Apps 2019 Winners - Tueetor, Findjobs & Smartfuture',
			website: 'www.sgtech.org.sg',
			thumbnail: 'As a social enterprise, Findjobs app harness technology to bring online jobs readily available to the non-tech savvy, blue-collar and elderly workers. We removed typical user barriers such as registration, log-in with social media, deposition of resume and creation of passwords and replaced with features such as “enlarge text”, “voice job description”, “broadcast yourself”, Pokemon style of “scan nearby jobs” as well as a simple touch-&-tap non-cv job application. With these features, Findjobs can make the job search effortless and smarter. '
		},
		{
			link: 'https://omny.fm/shows/money-fm-893/findjobs-in-the-business-of-giving-back-even-amid',
			text: 'Findjobs: In the business of giving back even amid tough times',
			website: 'www.omny.fm',
			thumbnail: 'Findjobs is an app that is targeted at blue-collar workers and aims to bring the job to workers in the language they\'re comfortable with. Ivan Lim, Chief Product Officer, Findjobs shares more about the app and how they are stepping up to help those who have lost their jobs during this pandemic.'
		},
		{
			link: 'https://www.straitstimes.com/politics/parliament-community-groups-band-together-to-help-displaced-malaymuslim-workers',
			text: 'Parliament: Community groups band together to help displaced Malay/Muslim workers',
			website: 'www.straitstimes.com',
			thumbnail: 'Malay/Muslim workers who have lost their jobs or seen a drop in income amid the Covid-19 pandemic can get support from a network of community organisations, Minister of State for Manpower Zaqy Mohamad told Parliament on Tuesday (April 7).'
		},
		{
			link: 'https://www.beritaharian.sg/setempat/hab-bantu-warga-belajar-alat-digital-di-west-coast',
			text: 'A hub to help people learn digital tools on the West Coast (Malay Version)',
			website: 'www.beritaharian.sg',
			thumbnail: 'DAHULU, Encik Rosali Buang perlu meminta bantuan anak-anaknya memasukkan nama-nama dan nombor telefon kenalan beliau ke dalam telefon bijaknya.Sering, beliau juga perlu bimbingan mereka ketika menggunakan aplikasi WhatsApp.'
		},
		{
			link: 'https://berita.mediacorp.sg/mobilem/eksklusif/corona-outbreak/app-cari-kerja-dilancar-tawarkan-peluang-kerja-dekat-rumah/4430314.html',
			text: '\'Cari Kerja\' app launched; offering job opportunities near the population\'s home',
			website: 'berita.mediacorp.sg',
			thumbnail: 'Aplikasi \'Cari Kerja\' dilancarkan pada Sabtu (11 Apr) bagi membantu para penduduk mencari pekerjaan dengan lebih mudah dan pantas di sekitar kawasan kejiranan masing-masing.'
		},
		{
			link: 'https://www.unscrambled.sg/2018/09/13/inspired-by-his-own-mother-findjobs-was-created-to-help-other-blue-collar-workers/',
			text: 'Inspired by his own mother, Findjobs was created to help other blue collar workers',
			website: 'www.unscrambled.sg',
			thumbnail: ''
		},
		{
			link: 'https://storiesofasia.co/story/meet-the-brothers-who-built-an-app-specifically-for-blue-collar-workers-to-findjobs-1562822197',
			text: 'Meet The Brothers Who Built an App Specifically for Blue-collar Workers to Findjobs',
			website: 'www.storiesofasia.co',
			thumbnail: ''
		},
		{
			link: 'https://www.dbs.com/indonesia/newsroom/Social_Enterprise_a_Key_to_Economic_Growth_Follow_the_Discussion',
			text: 'Social Enterprise a Key to Economic Growth? Follow the Discussion!',
			website: 'www.dbs.com',
			thumbnail: ''
		},
		{
			link: 'https://photo.sindonews.com/galleryview/33115/dbs-se-meet-up-bahas-ekosistem-wirausaha-sosial',
			text: 'DBS SE Meet Up Bahas Ekosistem Wirausaha Sosial',
			website: 'photo.sindonews.com',
			thumbnail: ''
		},
		{
			link: 'https://www.straitstimes.com/singapore/traineeships-and-skills-training-programmes-to-result-in-permanent-job-prospects-after',
			text: 'Traineeships, attachments should help workers find permanent jobs after S\'pore economy recovers: Chee Hong Tat',
			website: 'www.straitstimes.com',
			thumbnail: ''
		},
		{
			link: 'https://mothership.sg/2020/07/iswaran-jobs-west-coast/amp',
			text: 'West Coast residents can access \'Job Stations\', get advice from \'Volunteer Career Navigators\' at 5 CCs',
			website: 'mothership.sg',
			thumbnail: ''
		},
		{
			link: 'https://www.unscrambled.sg/2020/04/04/when-jobs-are-scarce-what-can-vulnerable-workers-do-to-find-jobs/',
			text: 'When jobs are scarce, what can vulnerable workers do to find jobs?',
			website: 'www.unscrambled.sg',
			thumbnail: ''
		}, {
			link: 'https://berita.mediacorp.sg/mobilem/eksklusif/corona-outbreak/lebih-5-000-permohonan-diterima-melalui-app-carikerja/4445594.html',
			text: 'More than 5,000 applications were received through the \'Cari Kerja\' app (Malay Version)',
			website: 'berita.mediacorp.sg',
			thumbnail: ''
		},
		{
			link: 'https://www.straitstimes.com/politics/parliament-community-groups-band-together-to-help-displaced-malaymuslim-workers',
			text: 'Parliament: Community groups band together to help displaced Malay/Muslim workers',
			website: 'www.straitstimes.com',
			thumbnail: ''
		},
		{
			link: 'https://berita.mediacorp.sg/mobilem/eksklusif/corona-outbreak/zaqy-beri-jaminan-kumpulan-kerja-baru-komited-buka-peluang/4457466.html',
			text: 'Zaqy assure new working group committed to open jobs for Malay / Muslim workers impressed COVID-19 (Malay version)',
			website: 'berita.mediacorp.sg',
			thumbnail: ''
		},
		{
			link: 'https://www.straitstimes.com/singapore/north-east-cdc-launches-schemes-to-help-residents-find-jobs-in-the-community?utm_source=STSmartphone&utm_medium=share&utm_term=2020-06-27+20%3A14%3A56',
			text: 'North East CDC launches schemes to help residents find jobs in the community',
			website: 'www.straitstimes.com',
			thumbnail: ''
		},
		{
			link: 'https://www.sgsme.sg/cn/news/story20200704-49078#xtor=CS1-1',
			text: '\'SGUnited\' program provides 14,500 internship and training opportunities for those who have changed jobs (Chinese Version)',
			website: 'www.sgsme.sg',
			thumbnail: ''
		}
	]

	const settings = {
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		dots: true
	}

	return (
		<>
			<div className={`${styles.gray} py-5`}>
				<Container>
					<Row className='d-flex flex-column flex-md-row justify-content-center'>
						<Col className='d-flex justify-content-center align-items-center' xs={12} sm={5}>
							<Image src='/img/landing_page/gotcha.png' alt='interview' width='70%' className='mb-5' />
						</Col>
						<Col xs={12} lg={7}>
							<Slider {...settings}>
								{
									pressroomList.map((data, i) => (
										<div className='my-2' key={i}>
											<h3 className={styles.subtitle}>
												{
													data.text
												}
											</h3>
											<hr className={styles.divider} />
											<p className={styles.sliderParagraph}>{data.thumbnail !== '' ? data.thumbnail : data.text}</p>
											<Link href={data.link}>
												<a target="_blank">
													<FindjobsButton text='Read More' type='' />
												</a>
											</Link>
										</div>
									))
								}
							</Slider>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default Hunting