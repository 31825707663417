// Next
import React from 'react'
import type { NextPage } from 'next'

// style
import styles from '../styles/Index.module.scss'

// Other
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

// Components
import Hero from '../components/Home/Hero'
import Services from '../components/Home/Services'
import About from '../components/Home/About'
import Hunting from '../components/Home/Hunting'
import Partnership from '../components/Home/Partnership'
import Contact from '../components/Home/Contact'
import Kiosk from '../components/Home/Kiosk'
// import Clients from '../components/Home/Clients'

const Home: NextPage = () => {

	return (
		<>
			<div className={styles.landing}>
				<Hero />
				{/* <Services /> Ivan say remove*/}
				<About />
				<Hunting />
				<Kiosk />
				<Partnership />
				<Contact />
			</div>
		</>
	)
}

export async function getStaticProps({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, ['common']))
			// Will be passed to the page component as props
		}
	}
}

export default Home
