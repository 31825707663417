// Next
import Link from 'next/link'

// Bootstrap
import {
	Col,
	Row,
	Image,
	Container,
	NavLink
} from 'react-bootstrap'

// Style
import styles from '../../styles/Index.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import FindjobsButton from '../common/button/FindjobsButton'
import { useTranslation } from 'react-i18next'
import FindjobsHiringCompanyCarousellList from '../common/companyList/FindjobsHiringCompanyCarousellList'

const Hero = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className={`${styles.hero} ${styles.hfull} d-flex justify-content-center align-items-center`}>
				<Container>
					<Row className='d-flex flex-column-reverse flex-md-row'>
						<Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
							<h1 className={styles.title}>{t('company_landing_page.find_jobs_easily')}</h1>
							{/* <h1 className={styles.title}><span className={styles.highlight}>{t('company_landing_page.get_jobs_easily')}</span></h1> */}
							<div className='my-2 my-md-5'>
								<div className='d-flex flex-column flex-sm-row justify-content-start align-items-start'>
									<div className='mx-1 my-1'>
										<Link href='/jobs' passHref className='ml-4'>
											<NavLink>
												<FindjobsButton text={t('company_landing_page.look_for_a_job')} type='' />
											</NavLink>
										</Link>
									</div>
									<div className='mx-1 my-1'>
										<Link href='/employer' passHref>
											<NavLink>
												<FindjobsButton text={t('company_landing_page.post_a_job_now')} type='outline' />
											</NavLink>
										</Link>
									</div>
								</div>
							</div>
							<div>
								<p>{t('home.partners')}</p>
								<FindjobsHiringCompanyCarousellList />
							</div>
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-start mt-5'>
							<Image src='/img/landing_page/hero.png' alt='' width='100%' />
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default Hero